<template>
	<div
		fill-height
		class="bg-error"
		style="width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center"
	>
		
		<div 
			class="text-white text-center text-subtitle-1 mt-10" 
			style="margin-top: 15%; line-height: 60px; font-size: 2em;"
		>
			<div class="text-center">
				<v-icon 
					large 
					style="color: red;"
				>mdi-alert-outline</v-icon>
			</div>
			<p>{{  $language.common.not_exist_page }}</p>
			<p>{{  $language.common.reuse_after_check }}</p>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'ErrorNetwork'
		,created: function(){
			this.$emit('onLoad', this.program)
		}
		,data: function(){
			return {
				program: {
					title: 'Not Found Page'
					,not_header: true
					,not_footer: true
				}
			}
		}	
	}
</script>

<style>
	.bg-error {background-color: #15324E !important; color: white; text-align: center;}
	.fill-height {height: 100%; width: 100%;}
</style>